<template>
  <div>
  	<damo-header/>
    <div class="banner-box">
    	<div class="box">
    		<div class="service-banner">
    			<p class="p01 fw">全面提升客户服务体验</p>
    			<p class="p02">改善客户服务，为客户提供更专业、更全面的服务</p>
    		</div>
    	</div>
    	<div class="banner-con">
    		<img src="../static/images/banner_03.gif">
    	</div>
    </div>
    <div class="box service-lc section">
      <div class="service-title fw">服务实施流程</div>
      <div :class="[servertype ? 'gyt-box-ons gyt-box' : 'gyt-box']">
				<div class="gyt-box-da server-a">
					<p class="p01">申请合作</p>
					<p class="p02">热线：<i>400-888-9570</i></p>
					<p class="p02">邮箱：<i>admin@demogic.com</i></p>
				</div>
				<img class="gyt-box-db server-b" src="../static/images/next_icon.png">
				<div class="gyt-box-dc server-c">
					<p class="gyt-box-df server-d">顾问上门</p>
					<img class="gyt-box-de server-e" src="../static/images/combined_shape.png">
					<p class="gyt-box-df server-f">方案研讨</p>
					<img class="gyt-box-de server-g" src="../static/images/combined_shape.png">
					<p class="gyt-box-df server-h">确认合作</p>
					<img class="gyt-box-de server-i" src="../static/images/combined_shape.png">
					<p class="gyt-box-df server-j">平台搭建</p>
					<img class="gyt-box-de server-k" src="../static/images/combined_shape.png">
					<p class="gyt-box-df server-l">专业培训</p>
					<img class="gyt-box-de server-m" src="../static/images/combined_shape.png">
					<p class="gyt-box-df server-n">项目上线</p>
					<img class="gyt-box-de server-o" src="../static/images/combined_shape.png">
					<p class="gyt-box-df server-p">运营输出</p>
				</div>
				<img class="gyt-box-db server-q" src="../static/images/next_icon.png">
				<div class="gyt-box-da server-r" style="margin-top: 111px; padding-top: 10px;">
					<p class="p01">GIC后续实施跟进</p>
					<p class="p02">GIC用户培训并提供标准课件</p>
					<p class="p02">GIC技术部门持续运维</p>
					<p class="p02">系统更新说明和培训安排</p>
					<p class="p02">会员运营方案支撑</p>
					<p class="p02">7*12小时商家咨询</p>
				</div>
			</div>
    </div>
    <div class="service-yy section">
      <div class="box service-yy-box">
        <div class="service-title fw">运营服务</div>
        <div class="yyfw-box">
          <div class="d01">精细化的品牌沟通：秉持“一跟到底”的服务理念，拥有与技术人员对接的沟通机制，确保每一个用户问题，都能得到快速响应与专业解决。</div>
          <div class="d02">
            <img class="yyfw-img" src="../static/images/yicon01.png">
            <div class="d03">
              <p class="p01">基础运营服务</p>
              <p class="p02">工具赋能：顾客触达平台，导购管理平台，企业营销平台，移动数据平台</p>
            </div>            
          </div>
          <div class="d02">
            <img class="yyfw-img" src="../static/images/yicon02.png">
            <div class="d03">
              <p class="p01">用户精细化成长服务</p>
              <p class="p02">运营赋能：四大链路，十大场景，完成增长数字化</p>
            </div>            
          </div>
          <div class="d02">
            <img class="yyfw-img" src="../static/images/yicon03.png">
            <div class="d03">
              <p class="p01">全链路管理</p>
              <p class="p02">数据赋能：数据沉淀融合，数据洞察分析，数据营销决策</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  	<damo-footer @footclick="handleFoot"/>
  </div>
</template>
<script>
	import DamoHeader from '@/components/DamoHeader.vue'
	import DamoFooter from '@/components/DamoFooter.vue'
	export default {
	  name: 'service',
    metaInfo: {
      title: '达摩网络-让信息连接一切，成就所有零售者',
      link: [
        { rel: 'protocol', href: 'https://ding-doc.dingtalk.com/doc#/faquestions/ftpfeu' }
      ]
    },
	  components: {
			DamoHeader,
	    DamoFooter
	  },
		data() {
			return {
				scroll: '',
				servertype: false,
        selectId: null
			}
		},
    watch: {
      scroll: function () {
        this.loadSroll()
      }
    },
    mounted() {
      setTimeout(() => {
      	this.servertype = true
      }, 200)
      this.selectId = localStorage.getItem("toId");
      if(this.selectId) {
        this.jump(this.selectId)
        setTimeout(() => {
          localStorage.setItem("toId",'')
        }, 200);
      } else {
        console.log('wu')
      }
      // window.addEventListener('scroll', this.handleTabFix, true)
    },
		methods: {
			// handleTabFix() {
			//   this.scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			// },
			// loadSroll() {
			//   var self = this
			//   if(self.scroll > 200 && this.servertype === false){
			//     setTimeout(() => {
			//     	this.servertype = true
			//     }, 500)
			//   }
			// },
      jump(index) {
        var self = this;
        let jump = document.getElementsByClassName('section')
        // 获取需要滚动的距离
        let total = jump[index].offsetTop - 150
        // console.log(total)
        $('html, body').animate({ scrollTop: total }, { duration: 500,easing: "swing" })
        return false
      },
			handleFoot(e) {
				this.jump(e)
			}
		},
    destroyed(){
      localStorage.setItem("toId",'')
    }
	}
</script>
<style>
	.gyt-box-df{
		color: #FFFFFF;
		font-size: 16px;
		text-align: center;
		font-weight: bold;
		line-height: 24px;
	}
	.gyt-box-de{
		width: 16px;
		height: 10px;
		display: block;
		margin: 11px auto;
	}
	.gyt-box{
		overflow: hidden;
		padding-top: 44px;
		padding-left: 40px;
		padding-bottom: 116px;
	}
	.gyt-box-da{
		width:270px;
		background: rgba(255,255,255,1);
		box-shadow:0px 2px 9px 0px rgba(0,0,0,0.1);
		border-radius:8px;
		float: left;
		margin-top: 130px;
		padding: 20px 0 20px 54px;
		box-sizing: border-box;
	}
	.gyt-box-da .p01{
		color: #1C2E4A;
		font-size: 16px;
		font-weight: bold;
		line-height: 44px;
	}
	.gyt-box-da .p02{
		color: #60708F;
		position: relative;
		line-height: 26px;
		font-size: 14px;
	}
	.gyt-box-da .p02 i{
		font-style: italic;
	}
	.gyt-box-da .p02::before{
		content: "";
		width: 5px;
		height: 5px;
		background-color: #60708F;
		display: block;
		transform:rotate(45deg);
		-ms-transform:rotate(45deg);
		-moz-transform:rotate(45deg);
		-webkit-transform:rotate(45deg);
		-o-transform:rotate(54deg);
		position: absolute;
		top: 11px;
		left: -16px;
	}
	.gyt-box-db{
		width: 15px;
		height: 24px;
		margin: 192px 46px 0  38px;
		float: left;
		display: block;
	}
	.gyt-box-dc{
		width:315px;
		height:384px;
		background:linear-gradient(44deg,rgba(78,197,255,1) 0%,rgba(38,147,255,1) 100%);
		box-shadow:0px 0px 11px 0px rgba(147,173,207,0.3);
		border-radius:8px;
		float: left;
		padding-top: 32px;
	}
  .yyfw-box{
    padding-left: 43px;
    width: 590px;
  }
  .d03 .p01{
    font-size: 14px;
    color: #1C2E4A;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .d03 .p02{
    font-size: 14px;
    color: #60708F;
  }
  .yyfw-box .d01{    
    font-size: 14px;
    color: #60708F;
    line-height: 22px;
    margin: 16px 0 30px;
  }
  .yyfw-box .d02{
    margin-bottom: 22px;
  }
  .yyfw-box .d02 .yyfw-img{
    width: 34px;
    height: 34px;
    float: left;
    margin: 7px 15px 0 0;
  }
  .service-yy{
    width: 100%;
    height: 500px;
    background: url(../static/images/yuny_bg.png);
    background-repeat: repeat-x;
    background-size: 100%;
    background-position: top left;
  }
  .service-yy-box{
    background: url(../static/images/yy_img.png);
    background-size: 692px 460px;
    background-repeat: no-repeat;
    background-position-x: 510px;
    background-position-y: 60px;
    height: 100%;
    box-sizing: border-box;
    padding-top: 80px;
  }
  .service-lc{
    margin-top: 70px;
  }
  .service-banner{
  	padding-top: 150px;
    padding-left: 70px;
  	width: 100%;
  	position: relative;
  	z-index: 1;
    box-sizing: border-box;
  }
  .service-banner .p01{
  	font-size: 36px;
  	color: #023E99;
  	line-height: 50px;
  }
  .service-banner .p02{
  	color: #60708F;
  	line-height: 40px;
  	font-size: 20px;
  }
  .service-title{
    font-size: 22px;
    color: #1C2E4A;
    position: relative;
    line-height: 30px;
    height: 30px;
    padding-left: 42px;
    box-sizing: border-box;
  }
  .service-title:before{
    content: "";
    width: 19px;
    height: 5px;
    background: #023E99;
    position: absolute;
    top: 8px;
    left: 0;
    border-radius:5px;
    display: block;
  }
  .service-title:after{
    content: "";
    width: 17px;
    height: 5px;
    background: #C2D7F6;
    position: absolute;
    top: 17px;
    left: 10px;
    border-radius:5px;
    display: block;
  }
	.server-a{
		transition: opacity .2s;
		opacity: 0;
	}
	.server-b{
		transition: opacity .2s ease-in .2s;
		opacity: 0;
	}
	.server-c{
		transition: opacity .2s ease-in .4s;
		opacity: 0;
	}
	.server-q{
		transition: opacity .2s ease-in .6s;
		opacity: 0;
	}
	.server-r{
		transition: opacity .2s ease-in .8s;
		opacity: 0;
	}
	.gyt-box-ons .server-a, .gyt-box-ons .server-b, .gyt-box-ons .server-c, .gyt-box-ons .server-q, .gyt-box-ons .server-r{
		opacity: 1;
	}
</style>